import { __pbUser } from "~/stores/pb-user.store";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
    if (__pbUser().isReseller && __pbUser().isDeactivated) {
        log("❌ Is reseller but is deactivated");
        return navigateTo("/");
    }

    log("✅ Is active or not reseller");
});
